export function calculateKebutuhanEnergi({gender, beratBadan = 0, tinggiBadan = 0, usia = 0}) {
  /*
  1. Rumus untuk perempuan: [10 x berat badan (kg)] + [6.25 x tinggi badan (cm) - [5 x usia (tahun)] - 161
  2. Rumus untuk laki-laki: [10 x berat badan (kg)] + [6.25 x tinggi badan (cm) - [5 x usia (tahun)] + 5
  */
  let result
  let rumus

  if (gender === 'female') {
    result = ((10 * beratBadan) + ((6.25 * tinggiBadan) - (5 * usia))) - 161;
    rumus = `(10 x ${beratBadan}) + (6.25 x ${tinggiBadan}) - (5 x ${usia}) - 161`
  }
  if (gender === 'male') {
    result = ((10 * beratBadan) + ((6.25 * tinggiBadan) - (5 * usia))) + 5
    rumus = `(10 x ${beratBadan}) + (6.25 x ${tinggiBadan}) - (5 x ${usia}) + 5`
  }



  console.group('Kebutuhan Energi / Bassal')
  console.log(`gender(${gender})`)
  console.log(`weight(${beratBadan})`)
  console.log(`height(${tinggiBadan})`)
  console.log(`age(${usia})`)
  console.log(`rumus: ${rumus}`)
  console.log(`result(${result})`)
  console.groupEnd()

  return result
}
