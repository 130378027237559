<template>
  <c-box w="100%" maxWidth="590px">
    <c-form-control is-required :is-invalid="$v.fullName.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Nama Lengkap
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          placeholder="Masukkan Nama Lengkap"
          height="62px"
          v-model="fullName"
        />
      </c-input-group>
      <c-form-helper-text v-if="!$v.fullName.required" color="red.500" fontSize="12px">
        Nama lengkap harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control is-required :is-invalid="$v.age.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Umur (tahun)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          id="Age"
          placeholder="Masukkan Umur (tahun)"
          height="62px"
          v-model.number="age"
        />
      </c-input-group>
      <c-form-helper-text v-if="!$v.age.required" color="red.500" fontSize="12px">
        Umur harus diisi
      </c-form-helper-text>
      <c-form-helper-text v-if="!$v.age.numeric" color="red.500" fontSize="12px">
        Umur harus berupa angka
      </c-form-helper-text>
    </c-form-control>

    <c-form-control is-required :is-invalid="$v.gender.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Jenis Kelamin
      </c-form-label>
      <c-input-group size="md">
        <c-select v-model="gender" placeholder="Jenis Kelamin" height="62px">
          <option value="male">Laki-laki</option>
          <option value="female">Perempuan</option>
        </c-select>
      </c-input-group>
      <c-form-helper-text v-if="!$v.gender.required" color="red.500" fontSize="12px">
        Jenis Kelamin harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control is-required :is-invalid="$v.physicalActivity.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        % Aktivitas Fisik
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="physicalActivity"
          placeholder="Pilih % Aktivitas Fisik"
          height="62px"
        >
          <option value="0">0%</option>
          <option value="10">10%</option>
          <option value="20">20%</option>
          <option value="30">30%</option>
          <option value="40">40%</option>
          <option value="50">50%</option>
          <option value="60">60%</option>
          <option value="70">70%</option>
          <option value="80">80%</option>
          <option value="90">90%</option>
        </c-select>
      </c-input-group>
      <c-form-helper-text v-if="!$v.physicalActivity.required" color="red.500" fontSize="12px">
        Aktivitas fisik harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control is-required :is-invalid="$v.weight.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Berat Badan (kg)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          placeholder="Masukkan Berat Badan"
          height="62px"
          paddingRight="60px"
          v-model.number="weight"
        />
        <c-input-right-element width="4.5rem" mt="10px" marginRight="10px">
          <c-text color="black.900" fontSize="18px">kg</c-text>
        </c-input-right-element>
      </c-input-group>
      <c-form-helper-text v-if="!$v.weight.required" color="red.500" fontSize="12px">
        Berat badan harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control is-required :is-invalid="$v.height.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Tinggi Badan (cm)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          placeholder="Masukkan Tinggi Badan"
          height="62px"
          paddingRight="60px"
          v-model.number="height"
        />
        <c-input-right-element width="4.5rem" mt="10px" marginRight="10px">
          <c-text color="black.900" fontSize="18px">cm</c-text>
        </c-input-right-element>
      </c-input-group>
      <c-form-helper-text v-if="!$v.height.required" color="red.500" fontSize="12px">
        Tinggi badan harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control is-required :is-invalid="$v.bodyMassIndex.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Indeks Massa Tubuh (IMT) (kg/m2)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          placeholder="Masukkan Indeks Massa Tubuh (IMT) (kg/m2)"
          height="62px"
          paddingRight="90px"
          v-model.number="bodyMassIndex"
        />
        <c-input-right-element width="4.5rem" mt="10px" marginRight="30px">
          <c-text color="black.900" fontSize="18px">(kg/m2)</c-text>
        </c-input-right-element>
      </c-input-group>
      <c-form-helper-text v-if="!$v.bodyMassIndex.required" color="red.500" fontSize="12px">
        Indeks massa tubuh harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control is-required :is-invalid="$v.nutritionStatus.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Status Gizi
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="nutritionStatus"
          placeholder="Masukkan Status Gizi"
          height="62px"
        >
          <option value="below_normal">Berat Badan Kurang</option>
          <option value="normal">Normal</option>
          <option value="pre_obesity">Preobesitas</option>
          <option value="obesity">Obesitas</option>
        </c-select>
      </c-input-group>
      <c-form-helper-text v-if="!$v.nutritionStatus.required" color="red.500" fontSize="12px">
        Status gizi harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control is-required :is-invalid="$v.idealWeightMinimum.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Minimum Berat Badan Ideal
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          placeholder="Masukkan Minimum Berat Badan Ideal"
          height="62px"
          v-model.number="idealWeightMinimum"
        />
      </c-input-group>
      <c-form-helper-text v-if="!$v.idealWeightMinimum.required" color="red.500" fontSize="12px">
        Minimum berat badan ideal harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control is-required :is-invalid="$v.idealWeightMaximum.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Maximum Berat Badan Ideal
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          placeholder="Masukkan Maximum Berat Badan Ideal"
          height="62px"
          v-model="idealWeightMaximum"
        />
      </c-input-group>
      <c-form-helper-text v-if="!$v.idealWeightMaximum.required" color="red.500" fontSize="12px">
        Maximum berat badan ideal harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control is-required :is-invalid="$v.maternalCondition.$invalid" v-if="gender === 'female'" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Kondisi Keibuan
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="maternalCondition"
          placeholder="Kondisi Keibuan"
          height="62px"
        >
          <option value="0">Tidak ada / Hamil Trimester 1</option>
          <option value="340">Hamil Trimester 2</option>
          <option value="452">Hamil Trimester 3</option>
          <option value="330">Menyusui Bayi 0-6 Bulan</option>
          <option value="400">Menyusui Bayi 7-12 Bulan</option>
        </c-select>
      </c-input-group>
      <c-form-helper-text v-if="!$v.maternalCondition.required" color="red.500" fontSize="12px">
        Kondisi keibuan harus diisi
      </c-form-helper-text>
    </c-form-control>
    <c-form-control v-if="gender === 'female'" is-required :is-invalid="$v.extraMaternalCalories.$invalid" mb="30px">
      <c-form-label font-size="14px" color="#555" font-weight="400">
        Tambahan kalori kondisi keibuan
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="text"
          name="extraMaternalCalories"
          v-model="extraMaternalCalories"
          placeholder="Masukkan Tambahan kalori kondisi keibuan"
          height="62px"
        />
      </c-input-group>
      <c-form-helper-text v-if="!$v.extraMaternalCalories.required" color="red.500" fontSize="12px">
        Tambahan kalori kondisi keibuan harus diisi
      </c-form-helper-text>
      <c-form-helper-text v-if="!$v.extraMaternalCalories.numeric" color="red.500" fontSize="12px">
        Tambahan kalori kondisi keibuan harus berupa angka
      </c-form-helper-text>
    </c-form-control>

    <c-form-control is-required :is-invalid="$v.mealPlanGoal.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Tujuan Meal Plan
      </c-form-label>
      <textarea
        rows="3"
        v-chakra
        id="mealPlanGoal"
        placeholder="Masukkan Tujuan Meal Plan"
        d="block"
        border="1px solid #E2E8F0"
        border-radius="md"
        minHeight="150px"
        type="text"
        lineHeight="20px"
        p="10px"
        w="100%"
        v-model="mealPlanGoal"
      />
      <c-form-helper-text v-if="!$v.mealPlanGoal.required" color="red.500" fontSize="12px">
        Tujuan Meal Plan harus diisi
      </c-form-helper-text>
    </c-form-control>
    <c-form-control is-required :is-invalid="$v.maintainedThings.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Hal yang Perlu Dipertahankan
      </c-form-label>
      <textarea
        rows="3"
        v-chakra
        id="maintainedThings"
        placeholder="Masukkan Hal yang Perlu Dipertahankan"
        d="block"
        border="1px solid #E2E8F0"
        border-radius="md"
        minHeight="150px"
        type="text"
        lineHeight="20px"
        p="10px"
        w="100%"
        v-model="maintainedThings"
      />
      <c-form-helper-text v-if="!$v.maintainedThings.required" color="red.500" fontSize="12px">
        Hal yang Perlu Dipertahankan harus diisi
      </c-form-helper-text>
    </c-form-control>
    <c-form-control is-required :is-invalid="$v.fixedThings.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Hal yang Perlu Diperbaiki
      </c-form-label>
      <!-- <c-input-group size="md"> -->
      <textarea
        rows="3"
        v-chakra
        id="fixedThings"
        placeholder="Masukkan Hal yang Perlu Diperbaiki"
        d="block"
        border="1px solid #E2E8F0"
        border-radius="md"
        minHeight="150px"
        type="text"
        lineHeight="20px"
        p="10px"
        w="100%"
        v-model="fixedThings"
      />
      <!-- </c-input-group> -->
      <c-form-helper-text v-if="!$v.fixedThings.required" color="red.500" fontSize="12px">
        Hal yang Perlu Diperbaiki harus diisi
      </c-form-helper-text>
    </c-form-control>
    <c-form-control is-required :is-invalid="$v.basalEnergyNeeds.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Kebutuhan Energi Bassal (kkal)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          type="number"
          placeholder="Masukkan Kebutuhan Energi Bassal (kkal)"
          height="62px"
          v-model.number="basalEnergyNeeds"
        />
      </c-input-group>
      <c-form-helper-text v-if="!$v.basalEnergyNeeds.required" color="red.500" fontSize="12px">
        Kebutuhan Energi Bassal harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control is-required :is-invalid="$v.calorieDeficit.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Defisit kalori yang diberikan
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="calorieDeficit"
          placeholder="Pilih Defisit kalori yang diberikan"
          height="62px"
        >
          <option value="250">250</option>
          <option value="350">350</option>
          <option value="500">500</option>
          <option value="650">650</option>
          <option value="750">750</option>
        </c-select>
      </c-input-group>
      <c-form-helper-text v-if="!$v.calorieDeficit.required" color="red.500" fontSize="12px">
        Defisit kalori yang diberikan harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control is-required :is-invalid="$v.target.$invalid" marginBottom="30px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Target
      </c-form-label>
      <textarea
        rows="3"
        v-chakra
        id="target"
        placeholder="Masukkan Target"
        d="block"
        border="1px solid #E2E8F0"
        border-radius="md"
        minHeight="150px"
        type="text"
        lineHeight="20px"
        p="10px"
        w="100%"
        v-model="target"
      />
      <!-- </c-input-group> -->
      <c-form-helper-text v-if="!$v.target.required" color="red.500" fontSize="12px">
        Target harus diisi
      </c-form-helper-text>
    </c-form-control>
    <c-form-control is-required marginBottom="30px" display="flex">
      <c-button
        backgroundColor="superLightGray.900"
        px="26px"
        fontSize="18px"
        fontWeight="500"
        py="26px"
        borderRadius="58px"
        width="100%"
        color="darkGray.900"
        marginRight="20px"
        marginBottom="20px"
        as="router-link"
        :to="{ name: 'nutri.profile-gizi' }"
      >
        Batal
      </c-button>
      <c-button
        backgroundColor="brand.900"
        px="26px"
        fontSize="18px"
        fontWeight="500"
        py="26px"
        borderRadius="58px"
        width="100%"
        color="white"
        @click="$emit('submit')"
      >
        Simpan
      </c-button>
    </c-form-control>
  </c-box>
</template>

<script>
import { calculateBmi } from "@/utils/calculate-bmi.js";
import { calculateStatusGizi } from "@/utils/calculate-status-gizi.js";
import { calculateKebutuhanEnergi } from "@/utils/calculate-kebutuhan-energi";
import {
  calculateBeratBadanIdealMaximum,
  calculateBeratBadanIdealMinimum,
} from "@/utils/calculate-berat-badan-ideal";
import {numeric, required, requiredIf} from "vuelidate/lib/validators";
import debounce from "lodash/debounce"

export default {
  name: "EditProfileClientForm",
  props: ["value"],
  components: {},
  data() {
    return {
      fullName: null,
      gender: null,
      bodyMassIndex: null,
      nutritionStatus: null,
      idealWeightMinimum: null,
      idealWeightMaximum: null,
      basalEnergyNeeds: null,
      age: null,
      physicalActivity: null,
      weight: null,
      height: null,
      maternalCondition: null,
      extraMaternalCalories: null,
      fixedThings: null,
      mealPlanGoal: null,
      maintainedThings: null,
      calorieDeficit: null,
      target: null,
    };
  },
  computed: {
    _bmi() {
      let height = Number(this.value?.height ?? 0);
      let weight = Number(this.value?.weight ?? 0);

      return calculateBmi(height, weight);
    },
    _statusGizi() {
      return calculateStatusGizi(this._bmi);
    },
    _minBeratBadan() {
      return calculateBeratBadanIdealMinimum(this.height)
    },
    _maxBeratBadan() {
      return calculateBeratBadanIdealMaximum(this.height);
    },
    _kebutuhanEnergi() {
      return calculateKebutuhanEnergi({
        gender: this.gender,
        beratBadan: this.weight,
        usia: this.age,
        tinggiBadan: this.height,
      });
    },
    _value() {
      return {
        fullName: this.fullName,
        age: this.age,
        weight: this.weight,
        height: this.height,
        gender: this.gender,
        bodyMassIndex: this.bodyMassIndex,
        nutritionStatus: this.nutritionStatus,
        idealWeightMinimum: this.idealWeightMinimum,
        idealWeightMaximum: this.idealWeightMaximum,
        basalEnergyNeeds: this.basalEnergyNeeds,
        physicalActivity: this.physicalActivity,
        maternalCondition: (this.maternalCondition ?? '0'),
        extraMaternalCalories: +(this.extraMaternalCalories ?? 0),
        fixedThings: this.fixedThings,
        mealPlanGoal: this.mealPlanGoal,
        maintainedThings: this.maintainedThings,
        calorieDeficit: +this.calorieDeficit,
        target: this.target,
      };
    },
  },
  watch: {
    _value: {
      deep: true,
      handler(v) {
        if (v.bodyMassIndex !== this._bmi) v.bodyMassIndex = this._bmi;

        if (v.nutritionStatus == null || v.nutritionStatus === '') {
          v.nutritionStatus = this._statusGizi;
        }

        if (v.idealWeightMinimum !== this._minBeratBadan)
          v.idealWeightMinimum = this._minBeratBadan;
        if (v.idealWeightMaximum !== this._maxBeratBadan)
          v.idealWeightMaximum = this._maxBeratBadan;
        if (v.basalEnergyNeeds !== this._kebutuhanEnergi)
          v.basalEnergyNeeds = this._kebutuhanEnergi;

        this.$emit("input", v);
      },
    },
    value: {
      immediate: true,
      handler(val) {
        if (val == null) return;

        this.fullName = val.fullName;
        this.age = val.age;
        this.gender = val.gender;
        this.weight = val.weight;
        this.height = val.height;
        this.bodyMassIndex = val.bodyMassIndex;
        this.nutritionStatus = val.nutritionStatus;
        this.idealWeightMaximum = val.idealWeightMaximum;
        this.idealWeightMinimum = val.idealWeightMinimum;
        this.basalEnergyNeeds = val.basalEnergyNeeds;
        this.calorieDeficit = val.calorieDeficit?.toString();
        this.target = val.target;
        this.mealPlanGoal = val.mealPlanGoal;
        this.maintainedThings = val.maintainedThings;
        this.physicalActivity = val.physicalActivity;
        this.fixedThings = val.fixedThings;
        this.maternalCondition = val.maternalCondition?.toString();
        this.extraMaternalCalories = val.extraMaternalCalories;
      },
    },
  },
  validations: {
    fullName: {required},
    age: {required, numeric},
    weight: {required, numeric},
    height: {required, numeric},
    gender: {required},
    bodyMassIndex: {required},
    nutritionStatus: {required},
    idealWeightMinimum: {required},
    idealWeightMaximum: {required},
    basalEnergyNeeds: {required},
    physicalActivity: {required},
    maternalCondition: {required: requiredIf(v => v.gender === 'female')},
    extraMaternalCalories: {required: requiredIf(v => v.maternalCondition !== '0'), numeric},
    mealPlanGoal: {required},
    maintainedThings: {required},
    fixedThings: {required},
    calorieDeficit: {required},
    target: {required},
  }
};
</script>
