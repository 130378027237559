
export function calculateBeratBadanIdealMinimum(tinggiBadan = 0) {
    let result = (18.5) * ((tinggiBadan / 100) ** 2);
    return parseFloat(result.toFixed(2))
}

export function calculateBeratBadanIdealMaximum(tinggiBadan = 0) {
    let result = (24.9) * ((tinggiBadan / 100) ** 2);
    return parseFloat(result.toFixed(2))
}
