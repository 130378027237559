<template>
  <c-flex flex="1" flex-dir="column" overflow="auto" pb="30px">
    <c-box px="40px">
      <c-heading
        color="black.900"
        fontSize="24px"
        fontWeight="700"
        marginBottom="40px"
      >
        Profile Klien
      </c-heading>
      <EditProfileClientForm
        v-if="profile != null"
        @submit="submit"
        v-model="profile"
      />
    </c-box>
    <popup-container v-if="isPopupOpen" :max-w="600" @close="closePopup()">
      <c-flex flex-dir="column" max-w="600px">
        <c-flex
          flex-dir="column"
          align-items="center"
          justify-content="center"
          mt="60px"
          px="20px"
          pb="20px"
        >
          <c-image
            :src="require('./img-meal-plan-intro.svg')"
            alt="image intro illustration"
            w="200px"
          />
          <c-text
            font-weight="500"
            font-size="16px"
            text-align="center"
            color="#000000"
            mt="10px"
          >
            Yeay! Profile gizi klien berhasil disubmit
          </c-text>
        </c-flex>
        <c-button
          variant="outline"
          variant-color="brand"
          border-radius="24px"
          h="50px"
          w="122px"
          m="20px"
          mb="0"
          align-self="flex-end"
          @click.stop.prevent="closePopup()"
        >
          Mengerti
        </c-button>
      </c-flex>
    </popup-container>
  </c-flex>
</template>

<script>
import _ from "lodash"
import { mapActions, mapGetters } from "vuex"
import EditProfileClientForm from "./forms/edit-profile-client-form.vue"
import PopupContainer from "@/components/modals/popup-container"

export default {
  name: "ProfileClientEdit",
  components: { EditProfileClientForm, PopupContainer },
  data() {
    return {
      profile: null,
      program: null,
      //---
      isPopupOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      _profile: "mealPlan/profileGizi",
      axios: "axios",
    }),
    submittedValue() {
      return {
        clientId: this.clientId,
        id: this._profile.id,
        programId: this.programId,
        ..._.pick(this.profile, [
          "fullName",
          "age",
          "weight",
          "height",
          "bodyMassIndex",
          "nutritionStatus",
          "idealWeightMinimum",
          "idealWeightMaximum",
          "mealPlanGoal",
          "maintainedThings",
          "fixedThings",
          "basalEnergyNeeds",
          "maternalCondition",
          "extraMaternalCalories",
          "calorieDeficit",
          "target",
          "physicalActivity",
        ]),
      }
    },
  },
  watch: {
    _profile: {
      immediate: true,
      handler(profile) {
        this.profile = profile
      },
    },
  },
  methods: {
    ...mapActions({
      getProfileGizi: "mealPlan/getProfileGizi",
      updateProfileGizi: "mealPlan/updateProfileGizi",
      createProfileGizi: "mealPlan/createProfileGizi",
      listRiwayatPrograms: "profileProgram/listHistoryPrograms",
      getKuisioner: "clients/getKuisioner",
    }),
    closePopup() {
      this.isPopupOpen = false
      this.$router.push({ name: "nutri.profile-gizi" })
    },
    submit() {
      console.log("@submit", this.profile, this.submittedValue)
      if (this._profile.id != null) {
        this.updateProfileGizi({ profileGizi: this.submittedValue })
          .then((r) => r.data)
          .then((r) => {
            this.isPopupOpen = true;
          })
      } else {
        this.createProfileGizi(this.submittedValue).then((r) => {
          this.isPopupOpen = true;
          // this.$router.push({ name: "nutri.profile-gizi" })
        })
      }
    },
  },
  async mounted() {
    await this.getProfileGizi(this.clientId)
    this.program = await this.$store.dispatch(
      "mealPlan/getLatestProgramForNutri",
      this.clientId
    )
    let resp = await this.$store.dispatch(
      "kuisioner/getV2NutriAnswers",
      this.program.id
    )

    let namaDepan = resp.generalData.firstName
    let namaBelakang = resp.generalData.lastName
    let age = resp.generalData.age
    let gender = resp.generalData.gender
    let weight = resp.anthropometryData.weight
    let height = resp.anthropometryData.height

    if (gender != null && gender?.toLowerCase().includes("laki")) {
      gender = "male"
    }
    if (gender != null && gender?.toLowerCase().includes("perempuan")) {
      gender = "female"
    }

    this.$set(this.profile, "firstName", namaDepan)
    this.$set(this.profile, "lastName", namaBelakang)
    this.$set(this.profile, "fullName", `${namaDepan} ${namaBelakang}`)
    this.$set(this.profile, "age", age)
    this.$set(this.profile, "gender", gender)
    this.$set(this.profile, "weight", weight)
    this.$set(this.profile, "height", height)
  },
}
</script>
