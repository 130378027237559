<template>
  <portal to="main">
    <template>
      <!-- backdrop -->
      <c-box
        bg="#4f4f4fcc"
        h="100vh"
        w="100vw"
        pos="fixed"
        top="0"
        left="0"
        z-index="1001"
        @click.prevent.stop="$emit('close')"
      />

      <!-- content -->
      <c-flex
        bg="white"
        flex-dir="column"
        align-items="center"
        position="fixed"
        :bottom="['0px', 'auto']"
        :top="['auto', '50%']"
        :left="['auto', '50%']"
        :transform="['initial', 'translate(-50%, -50%)']"
        :w="['100vw', 'auto']"
        :h="['100vh', 'auto']"
        :max-w="['100vw', w]"
        :max-h="['100vh', h]"
        z-index="1002"
        :border-radius="['8px 8px 0 0', '8px']"
        p="16px"
      >
        <c-flex w="100%" align-items="flex-start">
          <c-button
            variant="ghost"
            m="0"
            p="0"
            min-w="24px"
            min-h="24px"
            h="24px"
            w="24px"
            @click.stop.prevent="$emit('close')"
          >
            <c-image
              :src="require('@/assets/icon-close-black.svg')"
              alt="icon close"
              h="24px"
              w="24px"
            />
          </c-button>
          <c-flex flex="1" ml="15px"><slot name="title" /></c-flex>
        </c-flex>
        <c-flex
          flex-dir="column"
          align-items="center"
          :mt="['20px', 0]"
        >
          <slot />
        </c-flex>
      </c-flex>
    </template>
  </portal>
</template>

<script>
export default {
  name: 'popup-container',
  props: {
    w: Number,
    h: Number,
  }
};
</script>

<style scoped></style>
